import api from './api';

export default {
  getOrdersForDates(queryParams) {
    return api.get(`/orders`, {
      params: queryParams
    });
  },
  downloadOrdersExcel(queryParams) {
    return api.get(`/orders/download`, {
      params: queryParams,
      responseType: 'blob'
    });
  },
  getOrderData(orderId) {
    return api.get('/orders/' + orderId);
  },
  getOrderActions(orderId) {
    return api.get('/orders/' + orderId + '/actions');
  }
};
