import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

function loadView(viewName) {
  return () => import(`@/views/${viewName}.vue`);
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: loadView('Home'),
    redirect: { name: 'reference' },
    children: [
      {
        path: 'logs',
        name: 'logs',
        component: loadView('Logs')
      },
      {
        path: 'operator',
        name: 'operator',
        component: loadView('ReferenceOperator')
      },
      { path: '/user', name: 'users', component: loadView('Users') },
      {
        path: '/station',
        name: 'station',
        component: loadView('Station')
      },
      {
        path: '/admin',
        name: 'admin',
        component: loadView('ReferenceAdmin')
      },
      {
        path: '/users',
        name: 'user',
        component: loadView('User')
      },
      {
        path: `/orders/:orderId`,
        name: 'order',
        component: loadView('Order')
      }
    ]
  },
  {
    path: '/reference',
    name: 'reference',
    component: loadView('ReferenceClient')
  },
  {
    path: '/payment',
    name: 'payment',
    component: loadView('ReferencePayment')
  },
  {
    path: '/complete',
    name: 'complete',
    component: loadView('ReferencePaymentComplete')
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('UserLogin')
  },
  {
    path: '/404',
    component: loadView('NotFound'),
    name: '404',
    props: true
  },
  {
    path: '/network-issue',
    component: loadView('NetworkIssue'),
    name: 'network-issue'
  },
  {
    path: '/oferta',
    component: loadView('Oferta'),
    name: 'oferta'
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
