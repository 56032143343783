export const isLoading = state => {
  return state.isLoading;
};
export const isError = state => {
  return state.isError;
};
export const result = state => {
  return state.result;
};
export const errorMessage = state => {
  return state.errorMessage;
};
export const userList = state => {
  return state.userList;
};
export const userData = state => {
  return state.userData;
};
export const roleList = state => {
  return state.roleList;
};
