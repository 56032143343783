import UserService from '@/services/UserService';
import Helper from '@/store/helper';

export const getUsers = ({ commit, dispatch }) => {
  commit('FETCH_USER_LIST');
  return UserService.get()
    .then(({ data }) => {
      commit('FETCH_USER_RESULT', data);
    })
    .catch(err => {
      commit('FETCH_USER_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка USER: `);
      throw err;
    });
};

export const addUser = ({ commit, dispatch }, user) => {
  commit('CREATE_USER_START');
  return UserService.post(user)
    .then(({ data }) => {
      commit('CREATE_USER_RESULT', data);
    })
    .catch(err => {
      commit('CREATE_USER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const editUser = ({ commit, dispatch }, user) => {
  commit('EDIT_USER_START');
  return UserService.put(user)
    .then(({ data }) => {
      commit('EDIT_USER_RESULT', data);
    })
    .catch(err => {
      commit('EDIT_USER_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, `Возникла ошибка при добавлении STATION:`);
      throw err;
    });
};
export const addRoles = ({ commit, dispatch }, data) => {
  commit('FETCH_ROLE_LIST');
  return UserService.role(data)
    .then(({ data }) => {
      commit('FETCH_ROLE_RESULT', data);
    })
    .catch(err => {
      commit('FETCH_ROLE_ERROR', Helper.getErrMessage(err));
      err.dispatchErrNotification(err, dispatch, `Возникла ошибка при получении списка USER: `);
      throw err;
    });
};
