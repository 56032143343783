export const FETCH_USER_LIST = state => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.errorMessage = '';
};
export const FETCH_USER_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = true;
  state.userList = response.users;
};
export const FETCH_USERS_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = errorMessage;
};

export const CREATE_USER_START = state => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.errorMessage = '';
};
export const CREATE_USER_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.userData = response.user;
};
export const CREATE_USER_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = errorMessage;
};

export const EDIT_USER_START = state => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.errorMessage = '';
};
export const EDIT_USER_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = response.success;
  state.userData = response.user;
};
export const EDIT_USER_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = errorMessage;
};

export const FETCH_ROLE_LIST = state => {
  state.isLoading = true;
  state.isError = false;
  state.result = false;
  state.errorMessage = '';
};
export const FETCH_ROLE_RESULT = (state, response) => {
  state.isLoading = false;
  state.isError = false;
  state.result = true;
  state.roleList = response.roles;
};
export const FETCH_ROLE_ERROR = (state, errorMessage) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = errorMessage;
};
