import api from './api';

export default {
  getReferenceList() {
    return api.get(`/orders`);
  },
  createOrder(order) {
    return api.post(`/orders`, order);
  },
  confirmAndGetPdf(order) {
    return api.post(`/orders/pdf`, order);
  },
  takeOrder(orderId) {
    return api.post(`/orders/reservation`, orderId);
  },
  addSignToPdf(object) {
    return api.post(`/orders/sign`, object, { responseType: 'blob' });
  },
  confirmAndSendPdf(order) {
    return api.post(`/orders/send`, order);
  },
  confirmPayment(token) {
    return api.post(`/orders/confirm`, token);
  },
  cancelOrder(order) {
    return api.post('/orders/cancel', order);
  }
};
