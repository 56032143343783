exports.dispatchErrNotification = function(err, dispatch, message) {
  let resultMessage = '';
  if (err.response?.status?.toString() === '401') {
    resultMessage = `Ошибка авторизации`;
  } else {
    let errMsg = `Неизвестная ошибка`;
    if (err.response?.data?.message) {
      errMsg = err.response.data.message;
    } else if (err.message) {
      errMsg = err.message;
    }
    resultMessage = message + errMsg;
  }

  dispatch(
    'notification/add',
    {
      type: 'error',
      message: resultMessage
    },
    { root: true }
  );
};

exports.getErrMessage = function(err) {
  let resultMessage = '';
  if (err.response?.status?.toString() === '401') {
    resultMessage = `Ошибка авторизации`;
  } else {
    let errMsg = `Неизвестная ошибка`;
    if (err.response?.data?.message) {
      errMsg = err.response.data.message;
    } else if (err.message) {
      errMsg = err.message;
    }
    resultMessage = errMsg;
  }
  return resultMessage;
};
