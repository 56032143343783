import api from './api';

export default {
  getLogsForDates(queryParams) {
    return api.get(`/logs`, {
      params: queryParams
    });
  },
  downloadLogsExcel(queryParams) {
    return api.get(`/logs/download`, {
      params: queryParams,
      responseType: 'blob'
    });
  }
};
