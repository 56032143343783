import Service from '@/services/CheckService';
import Helper from '@/store/helper';

export const clientCheck = ({ commit, dispatch }) => {
  commit('CLIENT_CHECK_START');
  return Service.clientCheck()
    .then(({ data }) => {
      commit('CLIENT_CHECK_RESULT', data);
    })
    .catch(err => {
      commit('CLIENT_CHECK_ERROR', Helper.getErrMessage(err));
      Helper.dispatchErrNotification(err, dispatch, 'Возникла ошибка проверки клиента: ');
      throw err;
    });
};
