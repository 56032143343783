import Vue from 'vue';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from './store';
import vue_moment from 'vue-moment';

require('moment/locale/ru');

Vue.use(vue_moment, {
  moment
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
