import api from './api';

export default {
  get() {
    return api.get('/users');
  },
  post(user) {
    return api.post('/users', user);
  },
  put(user) {
    return api.put('/users', user);
  },
  role(data) {
    return api.post('/users/role', data);
  }
};
