import AuthService from '@/services/auth';
import Helper from '@/store/helper';

export const login = ({ commit, dispatch }, authData) => {
  return AuthService.login(authData)
    .then(({ data }) => {
      const { token, user } = data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      commit('SET_CURRENT_USER', { token, user });
    })
    .catch(err => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      Helper.dispatchErrNotification(err, dispatch, `Ошибка авторизации: `);
      throw err;
    });
};

export const logout = ({ commit }) => {
  commit('LOGOUT_USER');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};
