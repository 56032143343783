export default {
  referenceList: [],
  isReferenceLoading: false,
  isReferenceError: false,
  referenceErrorMessage: '',
  isCreateReferenceLoading: false,
  isCreateReferenceError: false,
  isCreateReferenceComplete: false,
  createReferenceResult: false,
  createReferenceMessage: '',
  paymentToken: '',
  orderNumber: '',
  orderListTimer: null,
  //Confirm
  isConfirmLoading: false,
  isConfirmError: false,
  isConfirmComplete: false,
  confirmResult: false,
  confirmMessage: '',
  confirmOrderNumber: '',
  confirmOrderDate: '',
  confirmReferenceName: '',
  //PDF
  pdfData: null,
  isPdfLoading: false,
  isPdfError: false,
  pdfResult: false,
  pdfMessage: '',
  sendPdfData: '',
  isSendPdfLoading: false,
  isSendPdfError: false,
  sendPdfResult: false,
  sendPdfMessage: '',
  signPdfData: '',
  isSignPdfLoading: false,
  isSignPdfError: false,
  signPdfResult: false,
  signPdfMessage: '',
  //CANCEL
  isCancelLoading: false,
  isCancelError: false,
  isCancelComplete: false,
  cancelResult: '',
  cancelMessage: ''
};
