export const clientCheckLoading = state => {
  return state.clientCheckLoading;
};
export const clientCheckError = state => {
  return state.clientCheckError;
};
export const clientCheckComplete = state => {
  return state.clientCheckComplete;
};
export const clientCheckResult = state => {
  return state.clientCheckResult;
};
export const clientCheckMessage = state => {
  return state.clientCheckMessage;
};
