export const referenceList = state => {
  return state.referenceList;
};
export const isReferenceLoading = state => {
  return state.isReferenceLoading;
};
export const isReferenceError = state => {
  return state.isReferenceError;
};
export const referenceErrorMessage = state => {
  return state.referenceErrorMessage;
};
export const createReferenceResult = state => {
  return state.createReferenceResult;
};
export const createReferenceMessage = state => {
  return state.createReferenceMessage;
};
export const isCreateReferenceLoading = state => {
  return state.isCreateReferenceLoading;
};
export const isCreateReferenceError = state => {
  return state.isCreateReferenceError;
};

export const confirmResult = state => {
  return state.confirmResult;
};
export const confirmMessage = state => {
  return state.confirmMessage;
};
export const confirmOrderNumber = state => {
  return state.confirmOrderNumber;
};
export const confirmOrderDate = state => {
  return state.confirmOrderDate;
};
export const confirmReferenceName = state => {
  return state.confirmReferenceName;
};
export const isConfirmLoading = state => {
  return state.isConfirmLoading;
};
export const isConfirmComplete = state => {
  return state.isConfirmComplete;
};
export const isConfirmError = state => {
  return state.isConfirmError;
};

export const pdfResult = state => {
  return state.pdfResult;
};
export const pdfMessage = state => {
  return state.pdfMessage;
};
export const pdfData = state => {
  return state.pdfData;
};
export const isPdfLoading = state => {
  return state.isPdfLoading;
};
export const isPdfError = state => {
  return state.isPdfError;
};
export const sendPdfResult = state => {
  return state.sendPdfResult;
};
export const sendPdfMessage = state => {
  return state.sendPdfMessage;
};
export const sendPdfData = state => {
  return state.sendPdfData;
};
export const isSendPdfLoading = state => {
  return state.isSendPdfLoading;
};
export const isSendPdfError = state => {
  return state.isSendPdfError;
};

export const signPdfResult = state => {
  return state.signPdfResult;
};
export const signPdfMessage = state => {
  return state.signPdfMessage;
};
export const signPdfData = state => {
  return state.signPdfData;
};
export const isSignPdfLoading = state => {
  return state.isSignPdfLoading;
};
export const isSignPdfError = state => {
  return state.isSignPdfError;
};

export const paymentToken = state => {
  return state.paymentToken;
};

export const orderNumber = state => {
  return state.orderNumber;
};

export const orderListTimer = state => {
  return state.orderListTimer;
};

export const isCancelLoading = state => {
  return state.isCancelLoading;
};
export const isCancelError = state => {
  return state.isCancelError;
};
export const isCancelComplete = state => {
  return state.isCancelComplete;
};
export const cancelResult = state => {
  return state.cancelResult;
};
export const cancelMessage = state => {
  return state.cancelMessage;
};
